import React, { useEffect, useMemo } from 'react';
import EmptyImage from '../assets/images/empty-notificaciones.jpg';
import useGetNotifications from '../hooks/useGetNotifications';
import useNotificationMenuRecoil from '../hooks/useNotificationMenuRecoil';
import NotificationsData from './NotificationsData';
import { Box, ButtonBase, Copy, OffCanvas, SubTitle, VStack } from './SimpleUI';
import useUpdateNotificationsAsRead from '../hooks/useUpdateNotificationsAsRead';

const NotificationsContainer = () => {
  const {
    showNotifications,
    toggleNotifications,
  } = useNotificationMenuRecoil();
  const { data, refetch } = useGetNotifications();

  const {
    handleUpdateNotificationsAsRead,
    loading,
  } = useUpdateNotificationsAsRead({
    onCompleted: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (showNotifications) {
      refetch();
    }
  }, [refetch, showNotifications]);

  const allRead = useMemo(
    () => data?.getNotifications.notifications?.every((item) => item.isRead),
    [data?.getNotifications.notifications],
  );

  const renderEmpty = () => {
    return (
      <>
        <Box
          tag="img"
          src={EmptyImage}
          alt="Empty notifications"
          maxWidth="130px"
          display="block"
          marginX="auto"
          marginTop="xlarge"
          marginBottom="large"
        />
        <SubTitle fontWeight="medium" textAlign="center">
          No hay notificaciones
        </SubTitle>
      </>
    );
  };

  return (
    <OffCanvas
      isOpen={showNotifications}
      onClick={toggleNotifications}
      title="Notificaciones"
    >
      {!data?.getNotifications?.notifications.length ? (
        renderEmpty()
      ) : (
        <>
          {!allRead && (
            <Box display="flex" justifyContent={{ desktop: 'flex-end' }}>
              <ButtonBase
                borderColor="__darkPurple2"
                borderWidth={1.5}
                height="1.5rem"
                borderRadius="small"
                loading={loading}
                disabled={loading}
                onClick={handleUpdateNotificationsAsRead}
              >
                <Copy fontSize="label" fontWeight="bold">
                  Marcar como leido
                </Copy>
              </ButtonBase>
            </Box>
          )}
          <VStack space="medium" marginTop="midMedium">
            {data?.getNotifications?.notifications.map((item, key) => (
              <NotificationsData key={key} item={item} />
            ))}
          </VStack>
        </>
      )}
    </OffCanvas>
  );
};

export default NotificationsContainer;
