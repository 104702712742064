import { gql } from '@apollo/client';

const amountByCurrencyFields = `{
  USD
  ARS
  MXN
}`;

export const GET_PROJECTS = gql`
  query getProjectsByFilters($page: Float!, $filters: [FilterArgs!]!) {
    projectsByFilters(page: $page, filters: $filters) {
      count
      hasNext
      hasPrevious
      projects {
        id
        name
        estimatedInvestmentTerm
        anualProfit
        projectedProfitability
        profitability {
          value
          text
        }
        currentValue
        percentageInvested
        developer
        like
        currencyType

        investmentType {
          id
          description
        }

        resume {
          status
          thingsTodo
          developer
          investmentType
          projectType
          estimatedInvestmentTerm
          projectedProfitability
          projectedProfit
        }

        zone {
          descripcion
          latitud
          longitud
        }

        address {
          province
          locality
          street
        }

        state {
          name
          detail
        }

        image {
          photoHeader
          photosSlider
        }

        finalTerm
        finalProfitability

        properties {
          paymentMethods {
            id
            name
          }
        }

        inversionLimits {
          minUSD
          maxUSD
          minARS
          maxARS
        }

        bankAccounts {
          businessName
          cuit
          bank
          accountType
          accountNumber
          cbu
          additionalInfo
        }
        investmentAmount
        modelContract
        slug
        terms {
          value
          text
        }
      }
    }
  }
`;

export const GET_FILTERS_PROJECT = gql`
  query getFilters {
    filterList(type: PROJECT) {
      name
      filter
      fields {
        id
        name
      }
      min
      max
    }
  }
`;

export const GET_PROJECT_DETAIL = gql`
  query findProject($idProject: Float!) {
    getProjectById(idProject: $idProject) {
      id
      name
      currentValue
      percentageInvested
      investmentAmount
      like
      currencyType
      startDate
      isRequiredBankAccount
      address {
        street
        number
        floor
        neighborhood
        locality
        province
        postalCode
      }

      design

      materialsList
      descriptionsList

      properties {
        availability {
          id
          months
          amounts
          inversion_min
          inversion_max
        }
        paymentMethods {
          id
          name
        }
      }

      inversionLimits {
        minUSD
        maxUSD
        minARS
        maxARS
      }

      investmentType {
        id
        description
        subType
      }

      image {
        photoHeader
        photosSlider
      }

      resume {
        status
        thingsTodo
        developer
        investmentType
        projectType
        estimatedInvestmentTerm
        projectedProfitability
        projectedProfit
      }

      updates {
        date
        detail
      }

      zone {
        descripcion
        latitud
        longitud
      }

      faq {
        question
        answer
      }

      state {
        name
        detail
      }

      bankAccounts {
        businessName
        cuit
        bank
        accountType
        accountNumber
        cbu
        additionalInfo
        accountTypeId
      }

      modelContract
      information

      finalTerm
      finalProfitability
      projectedProfitability
      profitability {
        value
        text
      }
      terms {
        value
        text
      }
      estimatedInvestmentTerm
      projectCurrencies {
        id
        currency
      }
      anualProfit
      url
    }
  }
`;

export const GET_PROFILE_INFO = gql`
  query getProfileInfo {
    getUserInfo {
      name
      id
      fullData
      phone
      isLegalPerson
      lastName
      businessName
      genderName
      civilState
      birthDate
      nationality
      asesor
      cash
      email
      wallet
      address {
        street
        number
        floor
        province
        postalCode
        locality
      }
      documentNumber
      documentType
      photo {
        dniFront
        dniBack
        selfie
      }
      agent {
        name
        code
      }
      verifiedEmail
      referred
      expirationReferred
      passwordCreated
      clasification
      verifiedUser
      verifiedObservations
      pep
      inversor
      investmentAmount
      country {
        id
        name
      }
      rfc
      partnerId
      partnerCountry
      hizoLogin
      alliedCompany {
        id
        name
        domain
      }
    }
  }
`;

export const GET_ABOUT_INFO = gql`
  query {
    getAbout {
      name
      lastName
      role
      image
      linkedin
      order
      area
      active
    }
  }
`;

export const GET_PROJECT_PROFITS = gql`
  query getProfitabilityCalculator(
    $amount: Float!
    $projectId: Float!
    $term: Float
    $currencyId: Float!
  ) {
    profitabilityCalculator(
      amount: $amount
      projectId: $projectId
      term: $term
      currencyId: $currencyId
    ) {
      totalProfit
      profitText
      currentDolarQuote
      endDate
      currency
    }
  }
`;

export const GET_REINVESTMENT_DATA = gql`
  query getReinvestmentData {
    getReinvestmentData {
      isReinvestmentActive
      contractStatus
    }
  }
`;

export const GET_REINVESTMENT_PREVIEW = gql`
  query getReinvestmentPreview {
    getReinvestmentPreview {
      data {
        withdrawalPeriod
        amount
        rate
        annualProfit
        monthlyProfit
      }
    }
  }
`;

export const GET_PROVINCES = gql`
  query getComboByType {
    getComboByType(type: PROVINCE) {
      value
      label
    }
  }
`;

export const GET_LOCALIDAD = gql`
  query getComboByType($idProvince: Float) {
    getComboByType(idProvince: $idProvince, type: LOCALITY) {
      value
      label
    }
  }
`;

export const GET_CIVIL_STATUS = gql`
  query getComboByType {
    getComboByType(type: CIVIL_STATUS) {
      value
      label
    }
  }
`;

export const GET_GENDER = gql`
  query getComboByType {
    getComboByType(type: GENDER) {
      value
      label
    }
  }
`;

export const GET_DOCUMENT_TYPES = gql`
  query getComboByType {
    getComboByType(type: DOCUMENT_TYPES) {
      value
      label
    }
  }
`;

export const BANK_ACCOUNT_TYPES = gql`
  query getComboByType {
    getComboByType(type: BANK_ACCOUNT_TYPES) {
      value
      label
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query getNotification {
    getNotifications {
      notifications {
        id
        title
        message
        isRead
        href
      }
    }
  }
`;

export const GET_MY_INVESTMENTS = gql`
  query getInvestments($filters: PaginationParams) {
    getInvestments(filters: $filters) {
      items {
        id
        project
        projectTypeName
        status
        currencyType
        amount
        projectedProfit
        projectId
        paymentStatus
        paymentMethod
        paymentCurrency
        paymentAmount
        paymentCoupon
        startDate
        paymentDate
        image
        paymentLink
        originalCoin
        investedAmount
        tasa
        duration
        typeProjectId
        infoProfit {
          proyectada_actual
          proyectada_final
          final
          mensual_cobrado
          mensual
          acumulada
        }
        empower {
          monto
          liberar
        }
        endDate
      }
      states {
        stateId
        count
        name
      }
      currentPage
      lastPage
    }
  }
`;

export const GET_INVESTMENT_BY_ID = gql`
  query getInvestmentById($investmentId: Float!) {
    getInvestmentById(investmentId: $investmentId) {
      success
      data {
        id
        project
        projectTypeName
        status
        currencyType
        amount
        projectedProfit
        projectId
        paymentStatus
        paymentMethod
        paymentCurrency
        paymentAmount
        paymentCoupon
        startDate
        paymentDate
        image
        paymentLink
        originalCoin
        investedAmount
        tasa
        duration
        typeProjectId
        couponAmount
        infoProfit {
          proyectada_actual
          proyectada_final
          proyectada_anual
          final
          mensual_cobrado
          mensual
          acumulada
        }
        empower {
          monto
          liberar
        }
        adendaUrl
        finalizar {
          diasRetiroCapital
          liberar
          fechaFinalizacion
          reinvertir
          intereses
          interesesCobrados
          interesesACobrar
          monto
        }
        potenciar {
          liberar
          monto
        }
      }
    }
  }
`;

export const GET_USER_BALANCE = gql`
  query getBalances {
    getBalances {
      currency
      amountAvailable
      wallet {
        walletId
        amountAvailable
        type
      }
    }
  }
`;

export const GET_USER_TRANSACTIONS = gql`
  query getTransactions($page: Float!, $limit: Float!) {
    getTransactions(page: $page, limit: $limit) {
      current_page
      last_page
      transactions {
        currency
        amount
        status
        description
        type
        created_at
        investment_id
        operation_id
      }
    }
  }
`;

export const GET_BANK_ACCOUNTS = gql`
  query getAllBankAccounts {
    getAllBankAccounts {
      bankAccounts {
        id
        name
        lastName
        cuil
        bank
        accountType
        cbu
        identity
        identityType
        alias
        accountNumber
        accountIdentity
        primary
        can_remove
        accountTypeId
      }
    }
  }
`;

export const GET_COUPONS = gql`
  query getCoupons {
    getCoupons {
      success
      message
      userRefCode {
        code
        link
      }
      registeredUsers {
        registered_id
        name
        date_created
        status
        email
      }
    }
  }
`;

export const GET_NATIONALITIES = gql`
  query {
    getNationality {
      label
      value
    }
  }
`;

export const GET_GENERATE_PASSWORD = gql`
  query {
    createPassword {
      message
      success
    }
  }
`;

export const GET_FEATURED_PROJECTS = gql`
  query findProject($idProject: Float!) {
    getProjectById(idProject: $idProject) {
      id
      investmentType {
        id
        description
      }
      estimatedInvestmentTerm
      image {
        photoHeader
      }
      percentageInvested
      address {
        province
        locality
      }
      investmentType {
        id
        description
      }
      name
      currencyType
      profitability {
        value
        text
      }
      terms {
        value
        text
      }
      state {
        name
      }
      currentValue
      slug
    }
  }
`;

export const GET_COUPON_BY_CURRENCY = gql`
  query getCouponInvestments($findCoupon: CouponInvestment!) {
    getCouponInvestment(findCoupon: $findCoupon) {
      success
      message
      data {
        amount
      }
    }
  }
`;

export const GET_CURRENT_QUOTE = gql`
  query {
    getCurrentRate {
      success
      data {
        rate
      }
    }
  }
`;

export const GET_INVESTED = gql`
  query {
    getInvested {
      invertido
      gananciaPagada
      gananciaPorPagar
      gananciaTotal
      porcentajeAumento
      tieneMetas
    }
  }
`;

export const GET_COUPONS_USED = gql`
  query getPromoCoupons {
    getPromoCoupons(usado: false) {
      name
      code
      type
      percentage
      amount
      type
      percentage
      values {
        usd
        ars
        inv_min_ars
        inv_min_usd
        bonificacion_max_usd
        bonificacion_max_ars
      }
      expiration
      used
    }
  }
`;

export const GET_QUOTATIONS = gql`
  query getCurrentQuotation {
    getCurrentQuotation {
      data {
        id
        currencyId
        sell
        buy
      }
    }
  }
`;

export const GET_POST = gql`
  query getPost($page: Float!, $partners: Float!) {
    getPost(page: $page, partners: $partners) {
      success
      count
      featured {
        id
        slug
        status
        type
        link
        title
        status
        content
        excerpt
        cover
        yoast_head_json {
          article_modified_time
          article_published_time
          author
          canonical
          description
          og_locale
          og_site_name
          og_title
          og_type
          og_url
          title
          twitter_card
        }
      }
      data {
        id
        slug
        status
        type
        link
        title
        status
        content
        excerpt
        cover
        yoast_head_json {
          article_modified_time
          article_published_time
          author
          canonical
          description
          og_locale
          og_site_name
          og_title
          og_type
          og_url
          title
          twitter_card
        }
      }
    }
  }
`;

export const GET_POST_BY_ID = gql`
  query getPostById($id: Float!) {
    getPostById(id: $id) {
      title
      content
      cover
      yoast_head_json {
        article_modified_time
        article_published_time
        author
        canonical
        description
        og_locale
        og_site_name
        og_title
        og_type
        og_url
        title
        twitter_card
      }
    }
  }
`;

export const GET_FAQS = gql`
  query {
    getFaqs {
      success
      message
      categories {
        id
        name
        slug
        meta_title
        meta_description
        icon
      }
      questions {
        id
        question_category_id
        slug
        question
        answer
        meta_title
        meta_description
      }
    }
  }
`;

export const GET_FAQS_CATEGORY = gql`
  query getCategories($id: Float!) {
    getCategories(id: $id) {
      success
      message
      data {
        id
        name
        slug
        meta_title
        meta_description
        icon
      }
    }
  }
`;

export const GET_FAQS_BY_ID = gql`
  query getQuestionById($category_id: Float!) {
    getQuestionById(category_id: $category_id) {
      success
      message
      categories {
        id
        name
        slug
        meta_title
        meta_description
        icon
      }
      questions {
        id
        question_category_id
        question
        answer
        slug
        meta_title
        meta_description
      }
    }
  }
`;

export const GET_GOALS = gql`
  query getGoals($filters: PaginationParams) {
    getGoals(filters: $filters) {
      message
      data {
        currentPage
        lastPage
        items {
          id
          title
          estimated ${amountByCurrencyFields}
          invested ${amountByCurrencyFields}
          goal_type
          profitability
          status
          percentage
          startDate
          endDate
          canDelete
          duration
          accumulatedProfit ${amountByCurrencyFields}
          suggestedMonthlyAmount ${amountByCurrencyFields}
        }
        states {
          stateId
          count
          name
        }
      }
    }
  }
`;

export const GET_GOALS_BOX = gql`
  query getGoalBox {
    getGoalBox {
      success
      message
      data {
        id
        status
      }
    }
  }
`;

export const GET_GOAL_DETAIL = gql`
  query getGoalsDetail($id: Float!) {
    getGoalsDetail(id: $id) {
      success
      message
      data {
        id
        status
        monto ${amountByCurrencyFields}
        fecha_inicio
      }
      ganancia_proyectada ${amountByCurrencyFields}
    }
  }
`;

export const GET_PLAZOS = gql`
  query getDeadlines {
    getDeadlines {
      success
      message
      data {
        rentabilidad
        meses
      }
      minimo {
        ars
        usd
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query {
    getCategory {
      success
      message
      data {
        id
        name
      }
    }
  }
`;

export const GET_COUNTRY = gql`
  query getCountry {
    getCountry {
      success
      message
    }
  }
`;

export const GET_GOAL_BY_ID = gql`
  query getGoalById($id: Float!) {
    getGoalById(id: $id) {
      success
      message
      data {
        id
        title
        estimated ${amountByCurrencyFields}
        goal_type
        status
        invested ${amountByCurrencyFields}
        percentage
        profitability
        startDate
        endDate
        duration
        canDelete
        accumulatedProfit ${amountByCurrencyFields}
        suggestedMonthlyAmount ${amountByCurrencyFields}
        ganancia_proyectada ${amountByCurrencyFields}
      }
    }
  }
`;

export const GRAPHIC_SIMULATOR = gql`
  query investmentSimulator(
    $investmentSimulatorRequest: InvestmentSimulatorRequest!
  ) {
    investmentSimulator(
      investmentSimulatorRequest: $investmentSimulatorRequest
    ) {
      tasa
      ganancia_estimada
      slider {
        min
        max
        escala
        tipo_moneda
        monto
      }
      grafico {
        plazo {
          meses
          anios
        }
        tipo_moneda
        inversion_mensual
        ganancias {
          ganancia
          anio
          meses
        }
      }
    }
  }
`;

export const GET_AVAILABLE_BOOST = gql`
  query availableBoost {
    availableBoost {
      success
      message
      data {
        availableBoost
        ids
      }
    }
  }
`;
