import { useMutation } from '@apollo/client';
import { handleGraphqlError } from '../helpers';
import { UPDATE_NOTIFICATIONS_AS_READ } from '../apollo/mutations';

const useUpdateNotificationsAsRead = (options) => {
  const [
    handleUpdateNotificationsAsRead,
    { loading, data, error: createError },
  ] = useMutation(UPDATE_NOTIFICATIONS_AS_READ, options);

  const error = createError ? handleGraphqlError(createError) : undefined;

  return { handleUpdateNotificationsAsRead, loading, data, error };
};

export default useUpdateNotificationsAsRead;
